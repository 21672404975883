<template>
  <b-table
    ref="table"
    :items="getEvents"
    :fields="fields"
    :current-page="pagination.currentPage"
    :per-page="pagination.perPage"
    responsive
    show-empty
  >
    <template #cell(actions)="{item}">
      <b-dropdown
        variant="link"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <b-dropdown-item :to="{name:'view-community-event',params:{id:item.id} }">
          <feather-icon icon="FileTextIcon" />
          <span class="align-middle ml-50">View</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{name:'quick-event-add-single-session',query:{event:convertCommunityToQuickEvent(item)} }">
          <feather-icon icon="FileTextIcon" />
          <span class="align-middle ml-50">Convert to quick event</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{name:'community-event-attendees',params:{id:item.id} }">
          <feather-icon icon="FileTextIcon" />
          <span class="align-middle ml-50">Attendees</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{name:'community-event-feedback-reports',params:{id:item.id} }">
          <feather-icon icon="FileTextIcon" />
          <span class="align-middle ml-50">Feedback Reports</span>
        </b-dropdown-item>

        <share-deep-link
          :id="item.id"
          route="/quick_event_details"
          :additional-params="{classification:'scrapped',id:item.id}"
        />

        <toggle-status
          :item="item"
          :toggle-status="toggleStatus"
        />

        <b-dropdown-item
          v-if="$can('update','global-ScrappedEventController')"
          @click="exportEvent(item.id)"
        >
          <feather-icon icon="ShareIcon" />
          <span class="align-middle ml-50">Export Event</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$can('update','global-ScrappedEventController')"
          :to="{name:'edit-community-event',params:{id:item.id} }"
        >
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$can('destroy','global-ScrappedEventController')"
          @click="deleteEvent(item.id)"
        >
          <div class="text-danger">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>

</template>
<script>
import communityEventApi from '@/common/compositions/CommunityEvents/communityEventApi'
import ShareDeepLink from '../common/Table/ShareDeepLink.vue'
import ToggleStatus from '../common/Table/ToggleStatus.vue'

export default {
  name: 'CommunityEventTable',
  components: { ShareDeepLink, ToggleStatus },
  props: {
    getEvents: { type: Function, default: () => {} },
    pagination: { type: Object, default: () => {} },
  },
  computed: {
    fields() {
      return [
        'id',
        { key: 'name', sortable: true, label: 'title' },
        { key: 'eventCategory', formatter: v => v?.title },
        { key: 'eventSource' },
        { key: 'startDate', sortable: true },
        { key: 'endDate', sortable: true },
        { key: 'attendees_count', sortable: true },
        { key: 'status', sortable: true, formatter: v => (v ? 'Active' : 'Inactive') },
        'actions',
      ]
    },
  },
  setup() {
    const { exportEvent, deleteCommunityEvent, toggleStatus } = communityEventApi()
    return { exportEvent, deleteCommunityEvent, toggleStatus }
  },
  methods: {
    convertCommunityToQuickEvent(item) {
      const publishDateInHours = this.$moment(item.publish_date).diff(this.$moment(), 'hours')
      return JSON.stringify({
        ...item,
        eventDates: { startDate: this.$moment().isAfter(item.startDate) ? this.$moment().format('YYYY-MM-DD HH:mm') : item.startDate, endDate: this.$moment().isAfter(item.endDate) ? this.$moment().add({ hours: 1 }).format('YYYY-MM-DD HH:mm') : item.endDate },
        types: item.eventCategory,
        expectedAttendeesNumber: item.attendeesCapacity,
        generalNotes: item.general_notes,
        activateBeforeHours: publishDateInHours < 0 ? 65535 : publishDateInHours,
        url: item.onlineEventLink,
        isAutoActivate: true,
        location: {
          city: item.city,
          country: item.country,
          state: item.state,
          lng: item.lng,
          lat: item.lat,
          street: item.street,
          postal_code: item.postal_code,
        },
      })
    },
    deleteEvent(id) {
      this.deleteCommunityEvent(id).then(() => {
        this.$refs.table.refresh()
      })
    },
  },
}
</script>
<style lang="">

</style>
