<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        resource-name="global-ScrappedEventController"
        action="store"
        add-route-name="add-community-event"
        text="Add Community Event"
      />
    </div>

    <b-card header="Search">
      <b-form-row>
        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <b-form-group
            label="Name"
            label-for="search"
          >
            <search
              placeholder="Name"
              :search-query.sync="searchQuery.keyword"
              @refresh="refreshTable"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Duration"
            label-for="date-range-filter"
          >
            <date-range-filter
              :date-range.sync="searchQuery.range"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Status"
            label-for="boolean-status-filter"
          >
            <boolean-status-filter :status.sync="searchQuery.status" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <event-type-select
            :event-type.sync="searchQuery.categoryId"
          />
        </b-col>

        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable()" />
        </b-col>
      </b-form-row>
    </b-card>

    <b-card>
      <community-events-table
        ref="parentTable"
        v-bind="{getEvents,pagination}"
      />
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import CommunityEventsTable from '@/common/components/CommunityEvents/CommunityEventsTable.vue'
import paginationData from '@/common/compositions/common/paginationData'
import communityEventApi from '@/common/compositions/CommunityEvents/communityEventApi'
import Search from '@/common/components/common/Table/Search.vue'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import AddResource from '@/common/components/common/Table/AddResource.vue'
import BooleanStatusFilter from '@/common/components/common/FormInputs/BooleanStatusFilter.vue'
import EventTypeSelect from '@/common/components/QuickEvents/EventTypeSelect.vue'

export default {
  name: 'CommunityEventsList',
  components: {
    CommunityEventsTable,
    Search,
    DateRangeFilter,
    Pagination,
    SearchButton,
    AddResource,
    BooleanStatusFilter,
    EventTypeSelect,
  },
  data() {
    return {
      searchQuery: {
        range: [],
        status: null,
      },
    }
  },
  setup() {
    const { pagination } = paginationData()
    const { listCommunityEvents } = communityEventApi()
    return { listCommunityEvents, pagination }
  },
  methods: {
    getEvents(ctx) {
      return this.listCommunityEvents({
        page: this.pagination.currentPage,
        date_from: this.searchQuery.range[0],
        date_to: this.searchQuery.range[1],
        orderParameter: ctx.sortBy,
        orderDir: ctx.sortDesc ? 'DESC' : 'ASC',
        ...this.searchQuery,
      }).then(res => {
        this.pagination.totalRows = res.data.pagination.total
        return res.data.data
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
<style lang="">

</style>
